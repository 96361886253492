export const BG_PRIMARY = 'rgba(219,215,210,1)';
export const BG_SECONDARY = 'rgba(146,127,111,1)';
export const BG_ALTERNATIVE = 'rgba(114,101,89,1)';

export const BG_STEP_RSVP = 'rgba(114,101,89,1)';

export const TEXT_PRIMARY = '#1A1B1D';
export const TEXT_SECONDARY = 'rgba(255,255,255,1)';
export const TEXT_ALTERNATIVE = '#FEFEFE';

export const BTN_NAVIGATION_COLOR = '#403224';
export const NAVIGATION_ICON_COLOR = '#FFFFFF';

export const HASHTAG_STYLE = {
  fontFamily: 'body',
  fontWeight: 'light',
  fontSize: '3xl',
  fontStyle: 'italic',
};

export const BUTTON_PROPS = {};

export const DEFAULT_BUTTON_PROPS = {
  fontWeight: 'light',
  size: 'sm',
  fontSize: 'xl',
  padding: '17px 40px',
  fontStyle: 'italic',
  type: 'button',
  bgColor: 'bgSecondary',
  color: 'secondaryColorText',
  fontFamily: 'body',
  borderRadius: '0',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: '#1A1B1D',
};
