/////////////////////////////////////////////
// DEFAULT PROPS FOR COMPONENTS
/////////////////////////////////////////////

export const FONT_FAMILY = {
  heading: `Butler, sans-serif;`,
  body: `'Newsreader', serif;`,
  body_lv2: `'Poppins', serif;`,
};

export const TEXT_PROPS = {
  heading: {
    fontFamily: 'heading',
    fontSize: 'heading',
    fontWeight: 'heading',
    fontStyle: 'var(--fontItalic-heading)',
  },
  body: {
    fontFamily: 'body',
    fontSize: 'body',
    fontWeight: 'body',
    fontStyle: 'var(--fontItalic-body)',
    color: 'mainColorText',
    textAlign: 'center',
  },
  body_lv2: {
    fontFamily: 'body_lv2',
    fontSize: 'body_lv2',
    fontWeight: 'body_lv2',
    fontStyle: 'var(--fontItalic-body_lv2)',
    letterSpacing: '2px',
    textTransform: 'uppercase',
    textAlign: 'center',
  },
};
